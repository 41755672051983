import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const News = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <StaticImage
              src="../../assets/images/sun-icon.jpg"
              alt="about"
              style={{ verticalAlign: "middle", marginRight: "5px" }}
              width={32}
              height={32}
            />
            {t("News")}
          </span>
          <h2>{t("Current - News - Title - 1")}</h2>
          <p>{t("Current - News - Text - 1")}</p>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <div class="elfsight-app-20793938-458c-437a-b774-4077b74b2347" data-elfsight-app-lazy></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
